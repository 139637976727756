<template>
  <div class="col-12 text-center" style="margin-top: 50px">
    <span
      class="spinner-grow spinner-grow-md mr-1"
      role="status"
      aria-hidden="true"
    >
    </span>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      row: {
        //
      },
    };
  },
  mounted() {},
};
</script>
